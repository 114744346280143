import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Row } from '../components/helpers';
import CalKing from '../../src/Icons/CalKing';
import King from '../../src/Icons/King';
import Queen from '../../src/Icons/Queen';
import FullXl from '../../src/Icons/FullXl';
import Full from '../../src/Icons/Full';
import SplitKing from '../../src/Icons/SplitKing';
import Twin from '../../src/Icons/Twin';
import TwinXl from '../../src/Icons/TwinXl';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Icon from '@mfrm/mfcl/Icons/[Icon Name]'
`}</code></pre>
    <Playground __position={0} __code={'<Row>\n  <CalKing width={30} height={30} />\n  <King width={30} height={30} />\n  <Queen width={30} height={30} />\n  <FullXl width={30} height={30} />\n  <Full width={30} height={30} />\n  <SplitKing width={30} height={30} />\n  <Twin width={30} height={30} />\n  <TwinXl width={30} height={30} />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      CalKing,
      King,
      Queen,
      FullXl,
      Full,
      SplitKing,
      Twin,
      TwinXl,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <CalKing width={30} height={30} mdxType="CalKing" />
    <King width={30} height={30} mdxType="King" />
    <Queen width={30} height={30} mdxType="Queen" />
    <FullXl width={30} height={30} mdxType="FullXl" />
    <Full width={30} height={30} mdxType="Full" />
    <SplitKing width={30} height={30} mdxType="SplitKing" />
    <Twin width={30} height={30} mdxType="Twin" />
    <TwinXl width={30} height={30} mdxType="TwinXl" />
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      